<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent >
    <v-form v-model="valid" ref="formCartaCorrecao" lazy-validation>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title class="white--text">Carta de Correção</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
        </v-toolbar>
        <v-card-text class="pa-4">
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field label="Chave da Nota" v-model="nota.chave" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea label="Justificativa" v-model="correcao.descCorrecao" :rules="validarDescricao"></v-textarea>
            </v-col>
          </v-row> 
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="fechar()" text>Cancelar</v-btn>
          <v-btn color="primary" @click="enviar()">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  
  import formatador from '@/util/formatador';

  export default {
    
    props: ['dialog', 'nota'],

    data: () => ({
      valid: false,
      validarDescricao: [v => (v && v.length > 15) || "Descrição deve conter pelo menos 15 caracteres"],
      correcao: {
        idNota: '',
        seqEvento: '',
        chave: '',
        descCorrecao: ''
      }
    }),

    methods: {
      
      fechar(){
          this.$emit('fechar');
          this.limpar();
      },

      enviar(){
        if(this.$refs.formCartaCorrecao.validate()){
          if(this.nota.eventos){
            let quantidade = this.nota.eventos.length + 1
            this.correcao.seqEvento = formatador.formatarPadZero(quantidade, 2);
          }
          this.correcao.idNota = this.nota._id;
          this.correcao.chave = this.nota.chave;
          this.$emit('enviarCarta', this.correcao);
          this.limpar();
        }
      },

      limpar(){
        this.correcao = {
          chave: '',
          descCorrecao: ''
        }
      }
    }
  }
</script>